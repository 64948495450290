import React, { useState } from "react"
import "../../theme/type.scss"
import CustomFooter from "../../components/CustomFooter"
import GdprCookies from "../../components/GdprCookies"
import HeaderLayout from "../../components/layout/HeaderLayout"
import "../../theme/layout.scss"
import "../../theme/david.scss"

import ModalSuscribe from "../../components/layout/ModalSuscribre"


const SemanalBolsaMundo = ({ data }) => {
  //functions modal suscribe
  const [open, setOpen] = useState(false)

  function openSuscribe() {
    setOpen(true)
  }

  function closeSuscribe() {
    setOpen(false)
  }


  return (
    <>
      <HeaderLayout mb={true} suscribe={true} openModal={openSuscribe} />
      <div className="wrap-content">
        <div className="header--section">
          <h2 className="title--section">BOLSAS EN EL MUNDO</h2>
        </div>

        <div>

          <iframe className="airtable-embed"
                  src="https://airtable.com/embed/appTIIVoNxZwESogt/shrxfvLym0K3YhZIq?viewControls=on"
                  width="100%" height="533"
                  style={{ background: "transparent", border: "1px solid #ccc" }}></iframe>
        </div>
      </div>
      <CustomFooter openModal={openSuscribe} />
      <GdprCookies />
      {
        open && <ModalSuscribe closeModal={closeSuscribe} />
      }
    </>
  )
}

export default SemanalBolsaMundo
